import { differenceInDays, startOfDay, startOfToday } from 'date-fns';
import * as t from 'io-ts';
import { logApiError } from 'lib/apiLogger';
import { commerceToolsPrivilegedClient } from 'lib/commerceTools/privilegedClient';
import {
  convertToCanonicalOrderNumber,
  deriveReturnableQuantities,
} from 'lib/returns/returnUtilities';
import { validEmail, validOrderNumber } from 'lib/validation';
import { toCartItemMap } from 'models/cartItems/serializers';
import { CartItemModel } from 'models/cartItems/types';
import { toReturns } from 'models/returns/serializers';
import { ReturnModel } from 'models/returns/types';
import { NextApiHandler } from 'next';

interface RetrieveReturnableProductsFromOrderFailedResponse {
  success: false;
  message: string;
  redirectURL?: string;
}

interface RetrieveReturnableProductsFromOrderSuccessfulResponse {
  success: true;
  email: string;
  orderNumber: string;
  items: Record<string, CartItemModel>;
  returns: ReturnModel[];
}

export type RetrieveReturnableProductsFromOrderResponse =
  | RetrieveReturnableProductsFromOrderFailedResponse
  | RetrieveReturnableProductsFromOrderSuccessfulResponse;

export const RETURN_WINDOW_EXCEEDED_MESSAGE = 'RETURN_WINDOW_EXCEEDED';
export const GLOBAL_E_ORDER_MESSAGE = 'GLOBAL_E_ORDER';

// The return window in days, inclusive of the order date
const RETURN_WINDOW = 37;

const paramsSchema = t.interface({
  email: validEmail,
  locale: t.string,
  orderNumber: validOrderNumber,
});

export const retrieveReturnableProductsFromOrder: NextApiHandler<
  RetrieveReturnableProductsFromOrderResponse
> = async ({ method, body: params }, res) => {
  if (method !== 'POST') {
    res.setHeader('Allow', ['POST']);
    return res
      .status(405)
      .json({ success: false, message: `Method ${method} not allowed` });
  }

  if (!paramsSchema.is(params)) {
    return res.status(400).json({
      success: false,
      message: 'Please check that you have filled the fields out correctly',
    });
  }

  const orderNumber = convertToCanonicalOrderNumber(params.orderNumber);

  try {
    const { body } = await commerceToolsPrivilegedClient
      .orders()
      .withOrderNumber({ orderNumber })
      .get()
      .execute();

    // Bail if the input email and the order's email do not match
    if (body.customerEmail?.toLowerCase() !== params.email.toLowerCase()) {
      return res.status(400).json({
        success: false,
        message: "Your email address does not match this order's",
      });
    }

    if (body.custom?.fields.ge_id && body.customerEmail) {
      return res.status(404).json({
        success: false,
        message: GLOBAL_E_ORDER_MESSAGE,
        redirectURL: `https://web.global-e.com/Returns/Portal/mZtl?orderId=${body.custom?.fields.ge_id}&email=${body.customerEmail}`,
      });
    }

    // Bail if the order is past the tolerated order recency, inclusive of the order date
    if (
      differenceInDays(startOfToday(), startOfDay(new Date(body.createdAt))) >
      RETURN_WINDOW
    ) {
      return res.status(404).json({
        success: false,
        message: RETURN_WINDOW_EXCEEDED_MESSAGE,
      });
    }

    // Get the allowed returns for this order
    const returnableProducts = deriveReturnableQuantities(body);

    return res.status(200).json({
      success: true,
      email: params.email,
      orderNumber: params.orderNumber,
      items: toCartItemMap({
        lineItems: Object.values(returnableProducts),
        locale: params.locale,
      }),
      returns: toReturns({ order: body, locale: params.locale }),
    });
  } catch (e) {
    logApiError(e);

    return res.status(404).json({
      success: false,
      message: 'Unfortunately, we were unable to retrieve your order',
    });
  }
};
