import { parse, serialize } from 'cookie';
import {
  countries,
  defaultCountry,
  validateCountry,
} from 'lib/locale/countries';
import { ApiLocale } from 'lib/locale/types';
import { getCountryIsoFromLocale } from 'lib/util';
import { Currency, Language, localeModel } from 'models/locales/types';

export const splitPath = (path: string) =>
  path.split(/\/|#|\?/).filter(segment => Boolean(segment));

export const getLocaleFromPath = <T extends string | undefined>(
  path: unknown | undefined,
  fallback: T
): string | T => {
  if (typeof path !== 'string') {
    return fallback;
  }

  const pathArray = splitPath(path);

  if (!pathArray.length) {
    return fallback;
  }

  const [locale] = pathArray;

  if (localeModel.is(locale)) {
    return locale;
  }

  return fallback;
};

export const getCountryFromPath = <T extends string | undefined>(
  path: unknown | undefined,
  fallback: T
): string | T => {
  const locale = getLocaleFromPath(path, fallback);

  const country = locale?.split('-').slice(-1).pop();

  if (country && countries[country]) {
    return country;
  }

  return fallback;
};

export const getCurrencyFromPath = (path?: unknown): Currency => {
  if (typeof path !== 'string') {
    return 'USD';
  }

  const pathArray = splitPath(path);

  if (!pathArray.length) {
    return 'USD';
  }

  const [locale] = pathArray;

  const country = locale.split('-').slice(-1).pop();

  if (country && countries[country]) {
    return countries[country].currency;
  }

  return 'USD';
};

export const getLocaleFromCookie = <T extends string | undefined>(
  w: Window | undefined,
  fallback?: T
): string | T => {
  if (typeof w?.document?.cookie !== 'string') {
    return fallback as T;
  }

  const locale = parse(w.document.cookie)?.locale;

  if (localeModel.is(locale)) {
    return locale;
  }

  return fallback as T;
};

export const addYear = (d: Date): Date => {
  const date = new Date(+d);
  date.setUTCFullYear(date.getUTCFullYear() + 1);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

export const getCountryFromCookie = (
  w: Window | undefined
): string | undefined => {
  const locale = getLocaleFromCookie(w);

  return getCountryFromPath(locale, undefined);
};

export const getApiLocale = (locale: string): ApiLocale => {
  const countryCode = validateCountry(locale.split('-')[1], defaultCountry);

  const country = countries[countryCode];

  const language = locale?.split('-')[0] as Language;

  return {
    locale: locale,
    shopperLocale: `${language.toLowerCase()}-${country.code.toUpperCase()}`,
    country: country.code,
    currency: country.currency,
    displayCurrency: country.displayCurrency,
    paymentMethods: country.paymentMethods,
    store: country.store,
    language: locale?.split('-')[0] as Language,
  };
};

export const redirectToLocale = (countryCode: string, w: Window) => {
  const code = countryCode.toLowerCase();
  const country = validateCountry(code, undefined);

  if (typeof w?.document?.cookie !== 'string' || !country) {
    return;
  }

  const { origin, pathname, search, hash } = w.location;

  const storelessPathname = pathname.replace(/\/en\-[a-zA-Z]{2}\/?/, '/');

  const locale = `en-${country}`;

  w.document.cookie = serialize('locale', locale, {
    path: '/',
    expires: addYear(new Date()),
  });

  const globaleData = {
    countryISO: getCountryIsoFromLocale(locale),
  };

  w.document.cookie = serialize(
    'GlobalE_Data',
    globaleData as unknown as string,
    {
      path: '/',
      expires: addYear(new Date()),
      encode: val => JSON.stringify(val),
      domain: process.env.NEXT_PUBLIC_GLOBALE_DATA_COOKIE_DOMAIN,
    }
  );

  const currentLocation = `${w.location.href}`;
  const locationWithLocale = `${origin}/${locale}${
    storelessPathname !== '/' ? storelessPathname : ''
  }${search}${hash}`;

  if (currentLocation !== locationWithLocale) {
    w.location.href = locationWithLocale;
  }
};

export interface ResponseWithLocaleFallbackData<T> {
  localizedCollection?: {
    items?: T[];
  };
  defaultCollection?: {
    items?: T[];
  };
}

export const selectResponseLocale = <T>(
  data: ResponseWithLocaleFallbackData<T>
) => {
  const { localizedCollection, defaultCollection } = data;

  return localizedCollection?.items?.length
    ? localizedCollection
    : defaultCollection;
};

type GuessLocale = (args: {
  window: Window | undefined;
  path: string;
}) => string;

export const guessLocale: GuessLocale = ({ window, path }) => {
  const cookieLocale = getLocaleFromCookie(window);
  const pathLocale = getLocaleFromPath(path, undefined);

  if (pathLocale && pathLocale !== cookieLocale) {
    return pathLocale;
  }
  return cookieLocale ?? pathLocale ?? 'en-us';
};
