import {
  DesktopNavigationModel,
  MobileNavigationModel,
} from 'models/navigation/types';
import { ReactNode, createContext } from 'react';

interface Props {
  children: ReactNode;
  desktopNavigation: DesktopNavigationModel | null;
  mobileNavigation: MobileNavigationModel | null;
  isGrapheneEnabled?: boolean;
}

export const NavigationContext = createContext<Omit<Props, 'children'>>({
  desktopNavigation: null,
  mobileNavigation: null,
});

const NavigationProvider = ({
  children,
  mobileNavigation,
  desktopNavigation,
  isGrapheneEnabled,
}: Props) => {
  return (
    <NavigationContext.Provider
      value={{ mobileNavigation, desktopNavigation, isGrapheneEnabled }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
