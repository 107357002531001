import { Customer } from '@commercetools/platform-sdk';
import commerceTools from 'lib/commerceTools';
import { toKeyedObject } from 'lib/util';
import {
  ChangePasswordRequest,
  CreateAccountRequest,
  DownstreamCustomerState,
  RetrieveCustomerRequest,
  SaveAddressRequest,
  SaveUserInformationRequest,
} from 'store/customer/types';

export const modelResponse = (customer: Customer): DownstreamCustomerState => ({
  version: customer.version,
  email: customer.email,
  firstName: [customer.firstName].join(''),
  lastName: [customer.lastName].join(''),
  addresses: toKeyedObject(customer.addresses, 'id'),
  addressIds: customer.addresses.map(address => address.id as string),
  defaultShippingAddressId: [customer.defaultShippingAddressId].join(''),
  defaultBillingAddressId: [customer.defaultBillingAddressId].join(''),
});

export const retrieveCustomerRequest: RetrieveCustomerRequest = async ({
  token,
}) => {
  const { body } = await commerceTools
    .me()
    .get({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .execute();

  return modelResponse(body);
};

export const saveUserInformationRequest: SaveUserInformationRequest = async ({
  token,
  version,
  email,
  firstName,
  lastName = '',
}) => {
  const { body } = await commerceTools
    .me()
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        version,
        actions: [
          {
            action: 'changeEmail',
            email,
          },
          {
            action: 'setFirstName',
            firstName,
          },
          {
            action: 'setLastName',
            lastName,
          },
        ],
      },
    })
    .execute();

  return modelResponse(body);
};

export const changePasswordRequest: ChangePasswordRequest = async ({
  token,
  version,
  currentPassword,
  newPassword,
}) => {
  const { body } = await commerceTools
    .me()
    .password()
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        version,
        currentPassword,
        newPassword,
      },
    })
    .execute();

  return modelResponse(body);
};

export const saveAddressRequest: SaveAddressRequest = async ({
  token,
  version,
  address,
}) => {
  const { body } = await commerceTools
    .me()
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        version,
        actions: [
          {
            action: 'addAddress',
            address,
          },
        ],
      },
    })
    .execute();

  return modelResponse(body);
};

export const createAccountRequest: CreateAccountRequest = async ({
  token,
  email,
  password,
  firstName,
  lastName,
}) => {
  const { body } = await commerceTools
    .me()
    .signup()
    .post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        email,
        password,
        firstName,
        lastName,
      },
    })
    .execute();

  return modelResponse(body.customer);
};
