import { CountryMismatchToast } from 'components/CountryMismatchToast';
import { getCountryFromCookie, getCountryFromPath } from 'lib/locale';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { resetCart } from 'store/cart';
import { useDispatch, useSelector } from 'store/hooks';
import { Dispatch } from 'store/types';

const NON_REDIRECT_PATHS = [
  '/[locale]/bag',
  '/[locale]/bag/[bagId]',
  '/[locale]/checkout/login',
  '/[locale]/checkout',
  '/[locale]/confirmation/[cartId]',
  '/404',
  '/_error',
];

export function shouldShowCountryPicker({
  window,
  pathname,
  country,
}: {
  window?: Window;
  pathname: string;
  country?: string;
}) {
  if (!window || NON_REDIRECT_PATHS.some(route => pathname.startsWith(route))) {
    return false;
  }

  const cookieCountry = getCountryFromCookie(window);

  if (country !== undefined && country === cookieCountry) {
    return false;
  }

  return true;
}

type ResetCartOnCountryChange = (args: {
  dispatch: Dispatch;
  country: string | undefined;
  cartCountry: string;
}) => () => void;

export const resetCartOnCountryChange: ResetCartOnCountryChange =
  ({ dispatch, country, cartCountry }) =>
  () => {
    if (
      country &&
      cartCountry &&
      cartCountry.toLowerCase() !== country.toLowerCase()
    ) {
      dispatch(resetCart());
    }
  };

const LocaleListener: FC = () => {
  const dispatch = useDispatch();
  const { asPath, pathname } = useRouter();
  const country = getCountryFromPath(asPath, undefined);
  const cartCountry = useSelector(state => state.cart.country);
  const [showCountryPicker, setShowCountryPicker] = useState<boolean>(false);

  useEffect(() => {
    setShowCountryPicker(
      shouldShowCountryPicker({ window, pathname, country })
    );
  }, [country, pathname, setShowCountryPicker]);

  useEffect(() => {
    resetCartOnCountryChange({ dispatch, country, cartCountry })();
  }, [country, cartCountry, dispatch]);

  if (showCountryPicker) {
    return <CountryMismatchToast />;
  }

  return null;
};

export default LocaleListener;
