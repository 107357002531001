import { Attribute } from '@commercetools/platform-sdk';
import { findFirst } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';
import { fold, Option } from 'fp-ts/lib/Option';
import translate from 'lib/translate';
import {
  AttributesModel,
  enumerable,
  localisedEnumerable,
  localisedEnumerableArray,
  localisedText,
  number,
  text,
  textArray,
} from 'models/attributes/types';
import { Language } from 'models/locales/types';

type ToAttributes = (args: {
  attributes?: Attribute[];
  language: Language;
}) => AttributesModel;

export enum AttributeName {
  Size = 'size',
  Gender = 'gender',
  Colour = 'colour',
  Length = 'length',
  MaterialsText = 'materialsText',
  VariantProductDescription = 'variantProductDescription',
  CareInstructionsText = 'careInstructionsText',
  IncludedStores = 'includedStores',
  FinalSaleStores = 'finalSaleStores',
  Available = 'available',
  Activity = 'activity',
  Pattern = 'pattern',
  Fabric = 'fabric',
  Swatch = 'swatch',
  Tag = 'tag',
  SizeGuide = 'sizeGuide',
  DefaultForCategoryKeys = 'defaultForCategoryKeys',
  RelatedProducts = 'relatedProducts',
  techSpec = 'techSpec',
  Preorder = 'preorder',
  PreorderExpectedDispatch = 'preorderExpectedDispatch',
}

export interface EnumValue {
  key: string;
  label: string;
}

export const EmptyEnumValue = {
  key: '',
  label: '',
};

const getAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName
): Option<Attribute> =>
  pipe(
    attributes ?? [],
    findFirst(attribute => attribute.name == name)
  );

export const getBooleanAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  defaultValue: boolean
): boolean =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => attribute.value
  )(getAttribute(attributes, name));

export const getTextArrayAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  defaultValue: Array<string> = []
): string[] =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => attribute.value
  )(getAttribute(attributes, name));

export const getTextAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  defaultValue = ''
): string =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => attribute.value
  )(getAttribute(attributes, name));

export const getLocalisedTextAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  language: string,
  defaultValue = ''
): string =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => attribute.value[language]
  )(getAttribute(attributes, name));

export const getLocalisedEnumAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  language: string,
  defaultValue: EnumValue = EmptyEnumValue
): EnumValue =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => {
      return {
        key: attribute.value.key,
        label: attribute.value.label[language],
      };
    }
  )(getAttribute(attributes, name));

export const getLocalisedEnumSetAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  language: string,
  defaultValue: EnumValue[] = []
): EnumValue[] =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => {
      return attribute.value.map(
        (value: { key: string; label: Record<string, string> }) => {
          return {
            key: value.key,
            label: value.label[language],
          };
        }
      );
    }
  )(getAttribute(attributes, name));

export const getCustomAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  defaultValue: Attribute[][] = []
): Attribute[][] =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => {
      return attribute.value as Attribute[][];
    }
  )(getAttribute(attributes, name));

export const getEnumAttribute = (
  attributes: Attribute[] | undefined,
  name: AttributeName,
  defaultValue: EnumValue = EmptyEnumValue
): EnumValue =>
  fold(
    () => defaultValue,
    (attribute: Attribute) => {
      return {
        key: attribute.value.key,
        label: attribute.value.label,
      };
    }
  )(getAttribute(attributes, name));

export const toAttributes: ToAttributes = ({ attributes = [], language }) => {
  const t = translate(language);

  return attributes.reduce<AttributesModel>((acc, curr) => {
    if (text.is(curr)) {
      acc[curr.name] = [
        {
          key: curr.name,
          value: curr.value,
        },
      ];
      return acc;
    }

    if (number.is(curr)) {
      acc[curr.name] = [
        {
          key: curr.name,
          value: `${curr.value}`,
        },
      ];
      return acc;
    }

    if (localisedText.is(curr)) {
      acc[curr.name] = [
        {
          key: curr.name,
          value: t(curr.value),
        },
      ];
      return acc;
    }

    if (localisedEnumerableArray.is(curr)) {
      acc[curr.name] = curr.value.map(v => ({
        key: v.key,
        value: t(v.label),
      }));
      return acc;
    }

    if (localisedEnumerable.is(curr)) {
      acc[curr.name] = [
        {
          key: curr.value.key,
          value: t(curr.value.label),
        },
      ];
      return acc;
    }

    if (enumerable.is(curr)) {
      acc[curr.name] = [
        {
          key: curr.value.key,
          value: curr.value.label,
        },
      ];
      return acc;
    }

    if (textArray.is(curr)) {
      acc[curr.name] = curr.value.map(v => ({ key: v, value: v }));

      return acc;
    }

    return acc;
  }, {});
};
