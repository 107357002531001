import { createSlice } from '@reduxjs/toolkit';
import { replaceDownstreamState } from 'lib/immer';
import {
  ConfirmationState,
  DownstreamConfirmationState,
} from 'store/confirmation/types';
import { retrieveConfirmation } from 'store/confirmation/thunks';

export const initialState: ConfirmationState = {
  id: '',
  cartId: '',
  country: '',
  email: '',
  number: '',
  billingAddress: null,
  shippingAddress: null,
  shippingMethod: null,
  itemIds: [],
  items: {},
  discountCodes: {},
  cartDiscounts: {},
  total: null,
  displayPrice: null,
  inProgress: {
    retrieveConfirmation: false,
  },
  errors: {
    retrieveConfirmation: '',
  },
  taxPrice: {
    totalTax: null,
    totalGross: null,
  },
  taxInclusive: true,
};

const replaceState = replaceDownstreamState<
  DownstreamConfirmationState,
  ConfirmationState
>({
  omit: {
    errors: true,
    inProgress: true,
  },
});

const { reducer } = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(retrieveConfirmation.pending, () => ({
        ...initialState,
        inProgress: {
          retrieveConfirmation: true,
        },
      }))
      .addCase(retrieveConfirmation.rejected, (state, action) => {
        state.errors.retrieveConfirmation = action.error.message as string;
        state.inProgress.retrieveConfirmation = false;
      })
      .addCase(retrieveConfirmation.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.retrieveConfirmation = '';
        state.inProgress.retrieveConfirmation = false;
      });
  },
});

export default reducer;
