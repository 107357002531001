import { createSlice } from '@reduxjs/toolkit';
import { replaceDownstreamState } from 'lib/immer';
import {
  retrieveProductsFromOrder,
  returnProducts,
} from 'store/returns/thunks';
import { DownstreamReturnsState, ReturnsState } from 'store/returns/types';

export const initialState: ReturnsState = {
  email: '',
  orderNumber: '',
  itemIds: undefined,
  items: undefined,
  returns: undefined,
  complete: {
    returnProducts: false,
  },
  inProgress: {
    retrieveProductsFromOrder: false,
    returnProducts: false,
  },
  errors: {
    retrieveProductsFromOrder: '',
    returnProducts: '',
  },
};

const replaceState = replaceDownstreamState<
  DownstreamReturnsState,
  ReturnsState
>({
  omit: {
    errors: true,
    inProgress: true,
  },
});

const { reducer, actions } = createSlice({
  name: 'returns',
  initialState,
  reducers: {
    resetReturns() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(retrieveProductsFromOrder.pending, () => ({
        ...initialState,
        inProgress: {
          ...initialState.inProgress,
          retrieveProductsFromOrder: true,
        },
      }))
      .addCase(retrieveProductsFromOrder.rejected, (state, action) => {
        state.errors.retrieveProductsFromOrder = action.error.message as string;
        state.inProgress.retrieveProductsFromOrder = false;
      })
      .addCase(retrieveProductsFromOrder.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.retrieveProductsFromOrder = '';
        state.inProgress.retrieveProductsFromOrder = false;
      });

    builder
      .addCase(returnProducts.pending, state => {
        state.inProgress.returnProducts = true;
      })
      .addCase(returnProducts.rejected, (state, action) => {
        state.errors.returnProducts = action.error.message as string;
        state.inProgress.returnProducts = false;
      })
      .addCase(returnProducts.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.returnProducts = '';
        state.inProgress.returnProducts = false;
        state.complete.returnProducts = true;
      });
  },
});

export const { resetReturns } = actions;
export default reducer;
