import {
  Price,
  ProductVariant,
  ProductVariantChannelAvailability,
} from '@commercetools/platform-sdk';
import { ImageModel, PriceModel, VideoModel } from 'models/variants/types';

export const LOW_STOCK_LEVEL = 3;

export const deriveAvailability = (
  variant: ProductVariant
): Required<
  Pick<ProductVariantChannelAvailability, 'isOnStock' | 'availableQuantity'>
> => {
  const channels = variant.availability?.channels ?? {};

  const isOnStock = Object.values(channels).some(
    channel => channel.isOnStock === true
  );
  const availableQuantity = Object.values(channels).reduce(
    (total, channel) => total + (channel.availableQuantity ?? 0),
    0
  );

  return {
    isOnStock,
    availableQuantity,
  };
};

interface LengthOrSize {
  key: string;
  value: string;
}

interface DeriveSize {
  size: LengthOrSize[];
  length: LengthOrSize[];
}

export const deriveSize = ({ size, length }: DeriveSize) => {
  const derivedSize = length
    ? {
        key: `${size[0].key}-${length[0].key}`,
        value: `${size[0].value} - ${length[0].value}`,
      }
    : size[0];

  return derivedSize as LengthOrSize;
};

export const deriveLowStock = (quantity: number): boolean => {
  return quantity > 0 && quantity <= LOW_STOCK_LEVEL;
};

export const deriveImages = (
  images: ProductVariant['images'] = []
): ImageModel[] =>
  images?.map(image => {
    if (image.dimensions.w > 0 && image.dimensions.h > 0) {
      return {
        url: image.url,
        label: image.label ?? null,
        aspectRatio:
          Math.round((image.dimensions.w / image.dimensions.h) * 1000) / 1000,
        contentType: 'image/jpeg',
      };
    }

    return {
      url: image.url,
      label: image.label ?? null,
      aspectRatio: 0,
      contentType: 'image/jpeg',
    };
  });

export const deriveVideosFromAssets = (
  assets: ProductVariant['assets'] = []
): VideoModel[] =>
  assets.map(asset => ({
    url: asset.sources[1].uri,
    label: asset.name.en ?? null,
    aspectRatio: 0,
    contentType: asset.sources[0].contentType ?? null,
  }));

export const toPrice = (
  price: Pick<Price, 'value' | 'discounted'>
): PriceModel => {
  const {
    value: { currencyCode, centAmount, fractionDigits },
    discounted,
  } = price;

  const regularPrice = centAmount / Math.pow(10, fractionDigits);

  return {
    currencyCode,
    regularPrice,
    discountedPrice: discounted
      ? discounted.value.centAmount / Math.pow(10, fractionDigits)
      : regularPrice,
  };
};

export const derivePriceFromField = (
  variant: ProductVariant
): PriceModel | null => {
  if (!variant.price) {
    return null;
  }

  return toPrice(variant.price);
};

export const derivePriceFromList = (
  { prices }: Pick<ProductVariant, 'prices'>,
  country: string
): PriceModel | null => {
  if (!prices?.length) {
    return null;
  }

  const priceForCountry = prices.find(
    price => price.country?.toLowerCase() === country.toLowerCase()
  );

  if (priceForCountry) {
    return toPrice(priceForCountry);
  }

  const countrylessPrice = prices.find(price => !price.country);

  if (countrylessPrice) {
    return toPrice(countrylessPrice);
  }

  return null;
};

export const derivePrice = (variant: ProductVariant, country: string) =>
  derivePriceFromField(variant) ?? derivePriceFromList(variant, country);
