import {
  LineItem,
  LineItemReturnItem,
  Order,
} from '@commercetools/platform-sdk';
import { toKeyedObject } from 'lib/util';
import { ORDER_NUMBER_PATTERN } from 'lib/validation';

export const convertToCanonicalOrderNumber = (orderNumber: string): string => {
  const matches = orderNumber.match(ORDER_NUMBER_PATTERN);

  if (!matches?.[2]) {
    return '';
  }

  return 'PM-' + matches[2];
};

export const deriveReturnableQuantities = (
  order: Order
): Record<string, LineItem> => {
  const orderLineItems = toKeyedObject(
    order.lineItems.map(lineItem => ({ ...lineItem })),
    'id'
  );

  if (!order.returnInfo?.length) {
    return orderLineItems;
  }

  const returnableProducts = order.returnInfo
    ?.flatMap(({ items }) => items as LineItemReturnItem[])
    .reduce<Record<string, LineItem>>((acc, curr) => {
      (acc[curr.lineItemId].quantity as number) =
        acc[curr.lineItemId].quantity - curr.quantity;
      return acc;
    }, orderLineItems);

  for (const id in returnableProducts) {
    if (returnableProducts[id].quantity <= 0) {
      delete returnableProducts[id];
    }
  }

  return returnableProducts;
};
