import Button from 'components/Button';
import { containerId } from 'components/CountryMismatchToast/CountryMismatchToastContainer';
import Gap from 'components/Gap';
import { CloseButton } from 'components/HeaderButtons';
import { redirectAndChangeCartCountryOnCommit } from 'components/StoreManager/StoreManager';
import { Statement } from 'components/Typography';
import { countries, getCountryFromPath, getLocaleFromCookie } from 'lib/locale';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'store/hooks';
import styled, { css } from 'styled-components';

const Wrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  `
);

const ButtonContainer = styled.div(
  () => css`
    display: flex;
    width: 100%;
  `
);

const TextColumn = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    margin-right: 32px;
  `
);

const CountryMismatchContent = styled.div(
  () => css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
  `
);

export const toastId = 'countryMismatchToast';

export const CountryMismatchToast: FC = () => {
  const { asPath } = useRouter();
  const dispatch = useDispatch();
  const cartCountry = useSelector(state => state.cart.country);

  const currentCountryCode = getCountryFromPath(asPath, 'us');
  const currentCountry = countries[currentCountryCode];

  const detectedCountryCode = getCountryFromPath(
    getLocaleFromCookie(window),
    'us'
  );
  const detectedCountry = countries[detectedCountryCode];

  const updateCountry = useCallback(
    (countryCode: string) => {
      redirectAndChangeCartCountryOnCommit({
        dispatch,
        country: countryCode,
        cartCountry,
      })();

      toast.dismiss(toastId);
    },
    [cartCountry, currentCountry, dispatch]
  );

  useEffect(() => {
    if (
      !toast.isActive(toastId) &&
      detectedCountry.code !== currentCountry.code
    ) {
      toast.info(
        <Wrapper>
          <CountryMismatchContent>
            <TextColumn>
              <Statement color="grey7" size="2XS" variant="1">
                We think you are in {detectedCountry.name}.
              </Statement>
              <Statement color="grey7" size="2XS" variant="1">
                Update your location?
              </Statement>
            </TextColumn>
            <CloseButton
              onClick={() => {
                updateCountry(currentCountry.code);
              }}
            />
          </CountryMismatchContent>
          <Gap size={16} />
          <ButtonContainer>
            <Button
              variant="secondary"
              style={{ width: '150px', marginRight: '12px' }}
              onClick={() => {
                updateCountry(currentCountry.code);
              }}
            >
              {currentCountry.name}
            </Button>
            <Button
              variant="primary"
              style={{ width: '150px' }}
              onClick={() => {
                updateCountry(detectedCountry.code);
              }}
            >
              {detectedCountry.name}
            </Button>
          </ButtonContainer>
        </Wrapper>,
        {
          toastId,
          onClose: () => {
            updateCountry(currentCountry.code);
          },
          closeOnClick: true,
          containerId,
        }
      );
    }
  }, [currentCountry, detectedCountry, updateCountry]);

  return null;
};
